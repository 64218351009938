import React from "react";
import { textTruncate } from "../../utils/functions";

export default function SocialCardContent(props) {
  let message = "";
  if (props.article.attachments.instagram_item) {
    message = props.article.attachments.instagram_item.caption;
  }

  if (props.article.attachments.facebook_post) {
    message = props.article.attachments.facebook_post.message;
  }

  return (
    <div className="preview-text">{textTruncate(message, 100, "...")}</div>
  );
}
