import _ from "lodash";
import "./SocialFeed.scss";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Gallery from "../gallery/Gallery";
import Banner from "../slider/Banner";
import { fetchSocialFeed, updateSocialFeed } from "../../actions";
import Vib from "../vib/Vib";
import SocialCard from "./SocialCard";
import Loader from "../loader/Loader";

class SocialFeed extends Component {
  componentDidMount() {
    this.props.fetchSocialFeed();
  }

  onLoadMoreArticles = () => {
    if (this.props.pagination.older === null) {
      this.props.fetchSocialFeed();
    }
    this.props.updateSocialFeed(this.props.pagination.older);
  };

  renderSocialCards = () => {
    const articles = this.props.articles;

    return articles.map(article => {
      return <SocialCard key={article.id} article={article} />;
    });
  };

  renderContent() {
    if (_.isEmpty(this.props.articles) || !this.props.pagination) {
      return <Loader />;
    }

    const btn_text =
      this.props.pagination.older === null ? "LOAD LESS" : "LOAD MORE";

    return (
      <div className="ui container social-feed">
        <div className="social-cards">{this.renderSocialCards()}</div>

        <button className="social-load-more" onClick={this.onLoadMoreArticles}>
          {btn_text}
        </button>
        <br />
        <Vib />
      </div>
    );
  }

  render() {
    if (!this.props.isVerified) {
      return <div></div>;
    }

    return (
      <div className="fat-inspiration">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`FAT bastard wines | Social Feed `}</title>
          <meta
            name="description"
            content="Life is best lived in large delicious mouthfuls."
          />
          <link rel="canonical" href={window.location} />
        </Helmet>
        <Banner
          title="SOCIAL FEED"
          breadcrumbs="HOME / SOCIAL FEED"
          subtitle={`
            <p>Life is best lived in large delicious mouthfuls.</p>
          `}
        />

        <div>{this.renderContent()}</div>
        <Gallery pageId={5} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    articles: state.socialFeed.articles,
    pagination: state.socialFeed.pagination,
    isVerified: state.isVerified
  };
};

export default connect(
  mapStateToProps,
  {
    fetchSocialFeed,
    updateSocialFeed
  }
)(SocialFeed);
