import React from 'react'
import { Link } from 'react-router-dom';
import { FAT_BASTARD_API_IMAGE_PATH } from '../../utils/paths';

export default function RestaurantItem({ restaurant }) {
  return (
    <div className="recipe-box">
      <Link to={`restaurants/${restaurant.slug}`} >
        <div className="recipe-image-container">
          <img src={`${FAT_BASTARD_API_IMAGE_PATH}${restaurant.image}`} alt=""/>
          <div className="recipe-overlay">READ MORE</div>
        </div>
      
        <h3>{restaurant.title}</h3>
        <p style={{ fontSize: '16px', color: '#525252'}}>{restaurant.preview_text.slice(0, 100) + '...'}</p>
      </Link>
      
    </div>
  )
}
