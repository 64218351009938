import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import RegisterForm from "./RegisterForm";
import Banner from "../slider/Banner";
import { registerUser } from "../../actions";
import Button from "../buttons/Button";

class Register extends Component {
  onSubmit = formValues => {
    this.props.registerUser(formValues);
    this.props.history.push("/thank-you");
  };

  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`FAT bastard wines | VIB | Register `}</title>
          <meta
            name="description"
            content="The Exclusively enjoyed by South Africans who live life LARGE, the VIB club grants its Members access to exciting FAT bastard competitions, special offers and enticing promotions at our VIB partner restaurants."
          />
          <link rel="canonical" href={window.location} />
        </Helmet>
        {/* <div style={{ height: "320px" }}> */}
        <Banner
          title="VERY IMPORTANT <i>bastard</i>"
          breadcrumbs="HOME / VIB REGISTER"
          subtitle={`
           <p>The only thing better than a <span style="color: rgb(214, 176, 82);">Glass of Wine</span> with wonderful colour and rich, round palate is another glass of the <span style="color: rgb(214, 176, 82);">same wine.</span></p>
          `}
        />
        {/* </div> */}
        <RegisterForm onSubmit={this.onSubmit} />

        <div className="container">
          <hr />
          <div className="vib-restarurant-box">
            <div className="vib-r-content">
              <div className="content-box">
                <h2 className="title">VIB RESTAURANTS</h2>
                <p className="live_large" style={{ paddingRight: "25px" }}>
                  To <span className="gold_bold">LIVE LARGE</span>, you need to
                  gallop to your local watering hole and take a chunk-size bite
                  out of life. We unreservedly encourage you to try one of our
                  VIB restaurants. These restaurants have been handpicked for
                  their incomparable service, delectable food, and for being the
                  perfect locale to enjoy a bottle or 2 of{" "}
                  <span className="gold_bold">
                    FAT <i>bastard</i>
                  </span>{" "}
                  by the hearty mouthful.
                </p>
                <Button
                  color="purple"
                  toUrl="/restaurants"
                  title="VIEW RESTAURANTS"
                />
              </div>
              <div className="content-box">
                <img
                  src="../../resources/img/b1989d3bb6c57d06d9a8870a500fc936@2x.png"
                  alt=""
                />
              </div>
            </div>
          </div>

          <img src="" alt="" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    consumer: state.consumer
  };
};

export default connect(
  mapStateToProps,
  { registerUser }
)(Register);
