import React, { Component } from 'react'
import { connect } from "react-redux";
import { fetchCountries, fetchProvinces } from "../../actions";
import {
  Input
} from "reactstrap";
class Countrylist extends Component {
  constructor(props){
    super(props)
    this.select = React.createRef();
  }
  // componentDidMount(){
  //   this.props.fetchCountries('countries')
  // }

  // handleClick(e) {
  //   this.props.fetchProvinces('regions', e.target.value)
  // }

  renderCoutries(){
    return <option>South Africa</option>
  }

  render() {
    return (
      <Input {...this.props.input} type={this.props.type} ref={this.select} >
       { this.renderCoutries()}
      </Input>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return { 
    countries: Object.values(state.countries),
    provinces: Object.values(state.provinces),
   };
};

export default connect(
  mapStateToProps,
  { fetchCountries, fetchProvinces }
)(Countrylist);
