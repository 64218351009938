import "./Form.scss";
import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { sendMail } from "../../actions";
import { withRouter } from "react-router-dom";
import Content from "../content/Content";
import ReCAPTCHA from "react-google-recaptcha";

class Contact extends Component {
  state = {
    recapcha: {
      active: false,
      isVisible: false
    }
  };

  renderError({ error, touched }) {
    if (error && touched) {
      return (
        <div>
          <div className="input-error">{error}</div>
        </div>
      );
    }
  }

  renderRecapchaError() {
    if (this.state.recapcha.isVisible) {
      return (
        <div>
          <div className="input-error">The recapcha field is required</div>
        </div>
      );
    }
  }

  renderInput = ({ input, label, type, meta, placeholder }) => {
    const errorClass = `field ${meta.error && meta.touched ? "error" : ""}`;

    if (type === "checkbox") {
      return (
        <div className={`${errorClass}`}>
          <div className="ui checkbox">
            <input {...input} type={type} />
            <label style={{ lineHeight: "20px" }}>{label}</label>
          </div>
          {this.renderError(meta)}
        </div>
      );
    }

    return (
      <div className={`${errorClass}`}>
        <label>{label}</label>
        <input {...input} type={type} placeholder={placeholder} />
        {this.renderError(meta)}
      </div>
    );
  };

  renderTextArea = ({ input, label, meta, placeholder }) => {
    const errorClass = `field ${meta.error && meta.touched ? "error" : ""}`;
    return (
      <div className={`${errorClass}`}>
        <textarea {...input} placeholder={placeholder} />
        {this.renderError(meta)}
      </div>
    );
  };

  recapChange = () => {
    this.setState({
      recapcha: {
        active: true
      }
    });
  };

  renderRecaptchaField = ({ field, input, label, meta, placeholder }) => {
    return (
      <div>
        <div>
          <ReCAPTCHA
            sitekey="6LcnS74UAAAAADiu3a77dbqJhixfs7O_xmhMSbZj"
            onChange={this.recapChange}
          />
        </div>
        <div>{this.renderRecapchaError()}</div>
        <br />
      </div>
    );
  };

  onSubmit = async formValues => {
    if (!this.state.recapcha.active) {
      this.setState({
        recapcha: {
          isVisible: true
        }
      });
    } else {
      const response = await this.props.sendMail(formValues);

      response === "success" && this.props.history.push("/thank-you");
    }
  };
  render() {
    return (
      <div className="contact-form">
        <div className="container-extra-small">
          <Content
            disableFlex
            fullWidth
            centered
            item={{
              title: "GET IN TOUCH",
              subtitle:
                'To contact a representative, discover our products or even just to talk wine, we’re here for you. Write to us at <a href="mailto:info@fatbastardwine.co.za"><span style="color: #D6B052">info@fatbastardwine.co.za</span></Link>'
            }}
          />
          <form
            onSubmit={this.props.handleSubmit(this.onSubmit)}
            className="ui form error"
          >
            <Field
              name="first_name"
              placeholder="First name..."
              component={this.renderInput}
            />

            <Field
              name="last_name"
              placeholder="Last name..."
              component={this.renderInput}
            />

            <Field
              name="email"
              placeholder="Email..."
              component={this.renderInput}
            />

            <Field
              name="mobile"
              placeholder="Mobile..."
              component={this.renderInput}
            />

            <Field
              name="message"
              placeholder="Message..."
              component={this.renderTextArea}
            />

            <Field
              name="accept"
              component={this.renderInput}
              label="I agree that my submitted data is being collected & stored."
              type="checkbox"
            />

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Field name="recaptcha" component={this.renderRecaptchaField} />
            </div>

            <button className="btn purple centered">SEND MESSAGE</button>
          </form>
        </div>
      </div>
    );
  }
}

const validate = formValues => {
  const errors = {};
  
  if (!formValues.first_name) {
    // on run if the user did not enter a title
    errors.first_name = "You must enter a first name";
  }

  if (!formValues.last_name) {
    // on run if the user did not enter a title
    errors.last_name = "You must enter a last name";
  }

  if (!formValues.email) {
    // on run if the user did not enter a title
    errors.email = "You must enter an email";
  }

  if (!formValues.message) {
    // on run if the user did not enter a title
    errors.message = "You must enter a message";
  }

  if (!formValues.accept) {
    // on run if the user did not enter a title
    errors.accept = "You must agree to our terms";
  }

  return errors;
};

const formWrapped = reduxForm({
  form: "contact",
  validate: validate
})(Contact);

export default connect(
  null,
  { sendMail }
)(withRouter(formWrapped));
