import "./Wines.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchWines } from "../../actions";
import WineItem from "./WineItem";
import Content from "../content/Content";

import Slider from "react-slick";

class WineSlider extends Component {
  componentDidMount() {
    this.props.fetchWines();
  }

  renderWines() {
    if (!this.props.wines) {
      return <div>Loading...</div>;
    }

    return this.props.wines.map(wine => {
      return <WineItem wine={wine} key={wine.id} />;
    });
  }

  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (
       <div className="wine-slider">
         <Content disableFlex fullWidth centered item={{ 
            title: "THE FAT <i>bastards</i>", 
            subtitle: 'The only thing better than a <span style="color: #D6B052;">Glass of Wine</span> with wonderful colour and rich, round palate is another glass of the <span style="color: #D6B052">same wine.</span>'
          }} />
         <Slider {...settings}>{this.renderWines()}</Slider>
       </div> 
    );
  }
}

const mapStateToProps = state => {
  return {
    wines: Object.values(state.wines),
  };
};

export default connect(
  mapStateToProps,
  {
    fetchWines
  }
)(WineSlider);
