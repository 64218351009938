import './loader.scss'
import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

class Loader extends Component {
  renderLoader(){
    return (
      <div className="loader">
        <FontAwesomeIcon icon={faSpinner} pulse rotation={90} />
      </div>
    )
  
  }

  render() {
    return this.renderLoader()
  }
}


export default Loader
