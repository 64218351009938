import _ from 'lodash';
import './Recipes.scss'
import React, { Component } from 'react';
import RecipeItem from './RecipeItem'
import Content from '../content/Content'
import Loader from '../loader/Loader'

class RecipeList extends Component {
  renderRecipes() {
   

    return this.props.recipes.slice(0,this.props.amount).map(recipe => {
      return <RecipeItem key={recipe.id} recipe={recipe}/>
    })
  }

  renderHeader(){
    if(this.props.showHeader === true){
      return <Content disableFlex fullWidth centered item={{ 
        title: "FAT <i>Inspiration</i>", 
       subtitle: 'The only thing equal to drinking a glass of <span style="color: #D6B052">FAT <i>bastard</i></span> wine is indulging in a delicious meal paired perfectly with <span style="color: #D6B052">FAT <i>bastard</i></span> wines.' }} />
    } 
  }
  
  render() {
    if(_.isEmpty(this.props.recipes)){
      return <Loader />
    }
    return (  
      <div>
        {this.renderHeader()}
        <div className={`recipes ${this.props.classNames.join(' ')}`}>
          {this.renderRecipes()}
        </div>
      </div>
    )
  }
}

export default RecipeList

