import 'bootstrap/dist/css/bootstrap.min.css';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable'

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

// import 'semantic-ui/dist/semantic.min.css';
import 'fomantic-ui/dist/semantic.min.css';
import "./App.css";


import App from "./components/App";
import reducers from "./reducers";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, composeEnhancer(applyMiddleware(thunk)));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector("#root")
);
