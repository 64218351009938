import _ from 'lodash';
import { FETCH_WINES, FETCH_WINE } from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_WINES:
      return {...state, ..._.mapKeys(action.payload.items, 'slug')};

    case FETCH_WINE:
      const newState = {...state, ..._.mapKeys(action.payload, 'slug')};
      return newState;
  
    default:
      return state;
  }
};
