import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";

export default function SocialIconList() {
  return (
    <React.Fragment>
        <ul className="social-icon-list">
          <li>
            <a href="https://www.facebook.com/FATbastardWineSA" rel="noopener noreferrer" target="_blank">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/fatbastardsa/" rel="noopener noreferrer" target="_blank">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/FATbastardSA" rel="noopener noreferrer" target="_blank">
              {/*<FontAwesomeIcon icon={faTwitter} />*/}
              <svg xmlns="http://www.w3.org/2000/svg" fill="#5B2A81" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
            </a>
          </li>
        </ul>
    </React.Fragment>
  )
}
