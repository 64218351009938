import "./Dropdown.scss";
import React, { Component } from "react";
import { FAT_BASTARD_API_IMAGE_PATH } from "../../utils/paths";
class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.menu = React.createRef();
    this.dropDown = React.createRef();
  }

  renderItems = items => {
    return items.map(item => {
      const pdfpath = JSON.parse(item.pdf_path);
      return (
        <a
          className="item"
          data-value="1"
          key={item.id}
          href={FAT_BASTARD_API_IMAGE_PATH + pdfpath[0].download_link}
          target="_blank"
           rel="noopener noreferrer"
        >
          {item.title}
        </a>
      );
    });
  };

  toggleMenu = () => {
    if (this.dropDown.current.classList.contains("active")) {
      this.dropDown.current.classList.remove("active", "visible");
      this.menu.current.classList.remove("active", "visible");
    } else {
      this.dropDown.current.classList.add("active", "visible");
      this.menu.current.classList.add("transition", "visible");
    }
  };

  render() {
    const { theme, items } = this.props;

    return (
      <div
          onClick={this.toggleMenu}
          ref={this.dropDown}
          className={`ui selection dropdown ${theme}`}
        >
          <input type="hidden" name="gender" />
          <i className="dropdown icon" />
          <div className="default text">TASTING NOTES</div>
          <div ref={this.menu} className="menu">
            {this.renderItems(items)}
          </div>
        </div>
    );
  }
}

export default Dropdown;
