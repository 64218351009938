import { FETCH_GALLERY, CLEAR_GALLERY } from "../actions/types";

const INITIAL_STATE = {
  images: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_GALLERY:
      return INITIAL_STATE;
    case FETCH_GALLERY:
      return action.payload;

    default:
      return state;
  }
};
