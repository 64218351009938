import React from 'react'
import WineList from "../wines/WineList";
import WineSlider from '../wines/WineSlider'

export default function Wines(props) {
  const renderWineComponent = () => {
    if(window.innerWidth > 768){
      return <WineList showHeader amount={props.amount}/>
    } else {
      return <WineSlider />
    }
  }

  return (
    <div>
      {renderWineComponent()}
    </div>
  )
}
