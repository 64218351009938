import _ from "lodash";
import "./Video.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchVideo } from "../../actions";
import Content from "../content/Content";
import Video from "./Video";

class VideoDetail extends Component {
  componentDidMount() {
    this.props.fetchVideo(this.props.videoId);
  }

  renderVideo() {
    return <Video url={this.props.video.video_url} />;
  }

  render() {
    if (_.isEmpty(this.props.video)) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <Content
          item={this.props.video}
          animated
          disableFlex
          fullWidth
          centered
        />

        {this.renderVideo()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    video: state.videos
  };
};

export default connect(mapStateToProps, {
  fetchVideo
})(VideoDetail);
