import './Gallery.scss'
import React, { Component } from 'react'
import  { connect } from 'react-redux'

import { fetchPageGallery } from '../../actions';
import GalleryItem from './GalleryItem';

class GalleryList extends Component{
  componentDidMount() {
    this.props.fetchPageGallery(this.props.pageId, "standalone");
  }

  renderGallery(){
    if(!this.props.images){
      return <div>Loading</div>
    }

    return (
      this.props.images.map((image) => {
        return <GalleryItem key={image.id}  image={image} />
      })
    )
  }

  render() {
    return (
      <div className="gallery-standalone">
        {this.renderGallery()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    images: state.pageGallery.images,
  };
};

export default connect(
  mapStateToProps,
  {
    fetchPageGallery,
  }
)(GalleryList);