import './WhereToBuy.scss'
import React, { Component } from "react";
import Iframe from "react-iframe";
import Modal from "../Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,

} from "@fortawesome/free-solid-svg-icons"

class WhereToBuy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };
  }

  toggle = () => {
    this.setState({
      visible: !this.state.visible
    });

    // document.getElementById("root").scrollIntoView({
    //   behavior: "smooth",
    //   block: "start"
    // });
  };

  render() {
    return (
      <div className="wtb-container">

       

        
        
        {this.state.visible && (
          <Modal>
            <div className="wtb-close" onClick={this.toggle}><FontAwesomeIcon icon={faTimes} /></div>
            <div onClick={this.toggle} className="wtb-overlay">
              <Iframe
                className="wtb"
                ref={this.wtb}
                allow="geolocation"
                id="wtb-widget"
                src="https://vinimark.co.za/where-to-buy-iframe?user=fatbastard&producer=FAT bastard"
                name="myiFrame"
                scrolling="no"
                frameborder="1"
                marginheight="0px"
                marginwidth="0px"
                width="100%"
                allowfullscreen
              ></Iframe>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default WhereToBuy;
