import _ from 'lodash';
import "./Wines.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchWines } from "../../actions";
import WineItem from "./WineItem";
import Content from "../content/Content";
import Loader from '../loader/Loader'
import {Link} from 'react-router-dom'

class WineList extends Component {
  componentDidMount() {
    this.props.fetchWines();
  }

  renderWines() {
    return this.props.wines.slice(0,this.props.amount).map(wine => {
      return <WineItem wine={wine} key={wine.id} />;
    });
  }

  renderHeader(){
    if(this.props.showHeader){
      return <Content disableFlex fullWidth centered item={{ 
        title: "THE FAT <i>bastards</i>", 
        subtitle: 'The only thing better than a <span style="color: #D6B052;">glass of wine</span> with wonderful colour and rich, round palate is another glass of the <span style="color: #D6B052">same wine.</span>'
      }} />
    } 
  }

  render() {
    if (_.isEmpty(this.props.wines)) {
      return <Loader />;
    }

    return (
      <React.Fragment>
        <div className="fb_shop_btn_container" style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '50px'
        }}>
          <a href="https://shop.fatbastardwine.co.za/" className="btn purple" style={{margin: '10px 10px 50px 10px'}}>SHOP ONLINE</a>
          {/* <a href="https://vinimark.co.za/where-to-buy-iframe?user=fatbastard&producer=Fat%20Bastard" target="_blank" className="btn purple" style={{margin: '10px 10px 50px 10px'}}>WHERE TO BUY</a> */}
          <Link to="/where-to-buy" className="btn purple" style={{margin: '10px 10px 50px 10px'}}>WHERE TO BUY</Link>
        </div>
        {this.renderHeader()}
        <div className="wine-items">{this.renderWines()}</div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    wines: Object.values(state.wines),
  };
};

export default connect(
  mapStateToProps,
  {
    fetchWines
  }
)(WineList);
