import React, { Component } from "react";
import { FaChevronUp } from "react-icons/fa";

class ScrollToTopButton extends Component {
  constructor(props) {
    super(props)

    this.state = { isVvisible: false }
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  // Hide or show the menu.
  handleScroll = () => {
    if(window.pageYOffset === 0) {
      this.setState({ isVvisible: false })
    }else {
      this.setState({ isVvisible: true })
    }
  };

 handleScrollDown = () => {
    document.getElementById("root").scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  };

  renderButton = () => {
   
    if (this.state.isVvisible) {
      return (
        <div className="scroll-to-top-arrow">
          <FaChevronUp onClick={this.handleScrollDown} />
        </div>
      );
    }

    return null;
  };

  render() {
    return this.renderButton();
  }
}

export default ScrollToTopButton
