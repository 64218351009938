import React, { Component } from "react";
import { Input } from "reactstrap";
class Provincelist extends Component {

  renderProvinces() {

    const provinces = [
      'Western Province',
      'Eastern Cape',
      'Free State',
      'Gauteng',
      'KwaZulu-Natal',
      'Limpopo',
      'Mpumalanga',
      'Northern Cape',
      'North West',
    ]
    
    return provinces.map((item, index) => {
      return (
        <option key={index}>{item}</option>
      )
    })
  }

  render() {
    return (
      <Input
        {...this.props.input}
        type={this.props.type}
      >
        <option value="">Please select your province</option>
        {this.renderProvinces()}
      </Input>
    );
  }
}

export default Provincelist;
