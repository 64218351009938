import './Vib.scss'
import React, { Component } from 'react';
import VibCard from './VibCard';
import Content from '../content/Content'

class Vib extends Component {
  renderContent(){
    if(this.props.showHeader){
      return (
        <Content disableFlex fullWidth centered item={{ 
          title: "VERY IMPORTANT <i>bastards</i>", 
          subtitle: 'The <span style="color: #D6B052">VIB (Very Important <i>bastard</i>)</span> is an exclusive club that is reserved for the crème de la crème of <span style="color: #D6B052">FAT <i>bastards</i></span> in South Africa and keeps you up to date with everything you need to ensure your life is filled to the brim and overflows with good stuff.' 
        }} />
      )
    } 

  }

  render() {
    return (
      <div>
         { this.renderContent() }
        <div className="vib" >
          <VibCard 
            slug="vib/register"
            title="SIGN UP TO BECOME A VIB" 
            subtitle="Be in the know about FAT <i>bastard</i> competitions, special offers & promotions." 
            image="sign-up@2x.jpg"
          />
          <VibCard 
            slug="restaurants"
            title="VIB RESTAURANTS" 
            subtitle="Visit one of our VIB restaurants handpicked for their incomparable service and delectable food." 
            image="vib-restaurants@2x.jpg"
          />
        </div>
      </div>
    )
  }
}

export default Vib
