
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./authReducer";
import pageReducer from "./pageReducer";
import galleryReducer from "./galleryReducer";
import pageGalleryReducer from "./pageGalleryReducer";
import videoReducer from "./videoReducer";
import winesReducer from "./winesReducer";
import recipesReducer from "./recipesReducer";
import socialFeedReducer from "./socialFeedReducer";
import restaurantReducer from "./restaurantReducer";
import ageVerifiedReducer from "./ageVerifiedReducer";
import countryReducer from './countryReducer'
import provinceReducer from './provinceReducer'
import cityReducer from './cityReducer'
import verifyDisclaimerReducer from './verifyDisclaimerReducer'
import entryReducer from './entryReducer'

export default combineReducers({
  auth: authReducer,
  form: formReducer,
  page: pageReducer,
  sliderGallery: galleryReducer,
  pageGallery: pageGalleryReducer,
  videos: videoReducer,
  wines: winesReducer,
  recipes: recipesReducer,
  socialFeed: socialFeedReducer,
  restaurants: restaurantReducer,
  isVerified: ageVerifiedReducer,
  countries: countryReducer,
  provinces: provinceReducer,
  cities: cityReducer,
  disclaimerVerified: verifyDisclaimerReducer,
  entry: entryReducer,
});
