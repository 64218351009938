import _ from 'lodash';
import { FETCH_RECIPES, FETCH_RECIPE } from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_RECIPE:
      return {...state, ..._.mapKeys(action.payload, 'slug')};

    case FETCH_RECIPES:
      const newState = {...state, ..._.mapKeys(action.payload, 'slug')};
      return newState;

    default:
      return state;
  }
};
