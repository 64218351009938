import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';
import WineList from "../wines/WineList";
import Gallery from "../gallery/Gallery";
import Banner from "../slider/Banner";
import Content from '../content/Content'
import WhereToBuy from "./WhereToBuy";


class TheFatBastards extends Component { 
  renderContent(){
    return (
      <React.Fragment>
         <Helmet>
          <meta charSet="utf-8" />
          <title>FAT bastard wines | The Fat bastards</title>
          <meta name="description" content="The only thing better than a glass of FAT bastardwine with wonderful colour and a rich, round palate is another glass of the same wine." />
          <link rel="canonical" href={window.location} />
        </Helmet>

        <div className="ui container" >
          <Content disableFlex fullWidth centered animated item={{ 
          title: "THE FAT <i>bastards</i>", 
          subtitle: '' 
        }}/>
          <WineList showHeader={false}/>
          <WhereToBuy />
          <div style={{marginBottom: '40px'}}></div>
        </div>
        <Gallery pageId={2} />
      </React.Fragment>
    )
  }

  render(){
    if(!this.props.isVerified){
      return <div>loading</div>
    }

    return (
      <div>
        {/* <div style={{ height: "320px" }}> */}
          <Banner title="THE FAT <i>bastards</i>" breadcrumbs="HOME / THE FAT BASTARDS" subtitle={`
           <p>The only thing better than a <span style="color: rgb(214, 176, 82);">glass of wine</span> with wonderful colour and rich, round palate is another glass of the <span style="color: rgb(214, 176, 82);">same wine.</span></p>
          `}/>
        {/* </div> */}
        {this.renderContent()}
       
      </div>
    )
  }
}



const mapStateToProps = state => {
  return {
    isVerified: state.isVerified
  };
};

export default connect(mapStateToProps)(TheFatBastards)
