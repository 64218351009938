import React, { Component } from 'react'
import  { connect } from 'react-redux';
import RecipeList from './RecipeList'
import { fetchRecipes } from "../../actions";


class Recipes extends Component {
  componentDidMount(){
    this.props.fetchRecipes()
  }

  renderRecipeComponent = () => {
    return (
      <RecipeList recipes={this.props.recipes} showHeader={this.props.showHeader} amount={this.props.amount} classNames={['default']}/>
    )
    // if(window.innerWidth > 568){
    //   return <RecipeList recipes={this.props.recipes} showHeader={this.props.showHeader} amount={this.props.amount} classNames={['default']}/>
    // } else {
    //   return <RecipeSlider />
    // }
  }

  render() {
    return (
      <div>
        {this.renderRecipeComponent()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    recipes: Object.values(state.recipes),
  };
};

export default connect(
  mapStateToProps,
  {
    fetchRecipes
  }
)(Recipes);
