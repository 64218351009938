import { VERIFY_DISCLAIMER } from "../actions/types";

export default (state = false, action) => {
  switch (action.type) {
    case VERIFY_DISCLAIMER:
        return action.payload;  
    default:
      return state;
  }
};
