import React from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { FAT_BASTARD_API_IMAGE_PATH } from '../../utils/paths';

export default function RecipeItem({ recipe }) {
  const { slug, image, name, preview_text } = recipe;

  return (
    <div className="recipe-box">
      <Link to={`/fat-inspirations/${slug}`}>
        <div className="recipe-image-container">
          <img
            src={`${FAT_BASTARD_API_IMAGE_PATH}${image}`}
            alt=""
          />
          <div className="recipe-overlay">READ MORE</div>
        </div>

        <h3>{ReactHtmlParser(name)}</h3>
        <div style={{ fontSize: "15px", textAlign: "left", color: "#525252" }}>
          {ReactHtmlParser(preview_text)}
        </div>
      </Link>
    </div>
  );
}
