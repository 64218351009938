import { FAT_BASTARD_API_IMAGE_PATH } from './paths';

export const apiImagePath = (image) => {
  return FAT_BASTARD_API_IMAGE_PATH + image;
}

export const textTruncate = function(str, length, ending) {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

export const syllabify = (words) => {
  const syllableRegex = /[^aeiouy]*[aeiouy]+(?:[^aeiouy]*$|[^aeiouy](?=[^aeiouy]))?/gi;
  return words.match(syllableRegex);
}