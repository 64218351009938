import "./Register.scss";

import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import MediaHeading from "../MediaHeading";
import CountryList from "../world/CountryList";
import ProvinceList from "../world/ProvinceList";

import {
  Button,
  Container,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  FormFeedback
} from "reactstrap";

class RegisterForm extends Component {
  renderInput = ({ input, label, meta, type, id }) => {
    const hasError = meta.error && meta.touched ? true : false;
    const hasHelperText = meta.error && meta.touched ? meta.error : "";

    if (hasError) {
      return (
        <FormGroup>
          <Input {...input} type={type} id={id} invalid placeholder={label} />
          <FormFeedback>{hasHelperText}</FormFeedback>
        </FormGroup>
      );
    }

    return (
      <FormGroup className={input.name}>
        <Input {...input} type={type} id={id} placeholder={label} />
      </FormGroup>
    );
  };

  renderCountryDropDown = ({ input, label, meta, type, id, options }) => {

    return (
      <FormGroup className={input.name}>
        <CountryList input={{ ...input }} type={type} />
      </FormGroup>
    );
  };

  renderProvinceDropDown = ({ input, label, meta, type, id, options }) => {

    return (
      <FormGroup className={input.name}>
        <ProvinceList input={{ ...input }} type={type} />
      </FormGroup>
    );
  };

  renderCheckboxLable(name, label) {
    if (name === "accept_terms") {
      return (
        <a
          style={{ color: "#212529", textDecoration: "underline" }}
          href="https://www.robertsonwinery.co.za/competition-terms-and-conditions/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {label}
        </a>
      );
    }

    return label;
  }

  renderCheckBox = ({ input, label, meta, type, id }) => {
    const hasError = meta.error && meta.touched ? true : false;

    if (hasError) {
      return (
        <FormGroup check>
          <label className="checkBoxContainer">
            {" "}
            {this.renderCheckboxLable(input.name, label)}
            <Input {...input} type={type} id={id} invalid />
            <span className="checkmark"></span>
            <FormFeedback>{meta.error}</FormFeedback>
          </label>
        </FormGroup>
      );
    }

    return (
      <FormGroup check>
        <label className="checkBoxContainer">
          {" "}
          {this.renderCheckboxLable(input.name, label)}
          <Input {...input} type={type} id={id} />
          <span className="checkmark"></span>
        </label>
      </FormGroup>
    );
  };

  renderRadio = ({ input, label, meta, type, id }) => {

    return (
      <FormGroup check>
        <label className="radioContainer">
          {" "}
          {label}
          <Input {...input} type={type} id={id} />
          <span className="radioCheckmark"></span>
        </label>
      </FormGroup>
    );
  };

  onSubmit = formValues => {
    this.props.onSubmit(formValues);
  };

  render() {
    const subscribed = this.props.consumer ? true : false;

    return (
      <React.Fragment>
        <Container style={{ maxWidth: "700px", marginTop: "100px" }}>
          <form
            className="register-form"
            onSubmit={this.props.handleSubmit(this.onSubmit)}
            style={{ width: "100%" }}
          >
            {subscribed && (
              <Field
                id="public_id"
                type="hidden"
                name="public_id"
                component={this.renderInput}
                label="public_id"
              />
            )}
            <MediaHeading heading={`PERSONAL INFORMATION`} />

            <Row form>
              <Col md={6}>
                <Field
                  id="first_name"
                  type="text"
                  name="first_name"
                  component={this.renderInput}
                  label="Enter First Name"
                />
              </Col>

              <Col md={6}>
                <Field
                  id="last_name"
                  type="text"
                  name="last_name"
                  component={this.renderInput}
                  label="Enter Last Name"
                />
              </Col>
            </Row>

            <Row form>
              <Col md={6}>
                <Field
                  id="email"
                  type="text"
                  name="email"
                  component={this.renderInput}
                  label="Enter Email"
                />
              </Col>

              <Col md={6} style={{ postition: "relative" }}>
                <Field
                  id="mobile"
                  type="text"
                  name="mobile"
                  component={this.renderInput}
                  label="Enter Mobile (No spaces)"
                  pattern="\d{3}\d{3}\d{4}"
                />
              </Col>
            </Row>

            <Row form className="d-flex align-items-center">
              <Col md={6}>
                <Row form>
                  <Col md={3}>
                    <Label>Gender: </Label>
                  </Col>

                  <Col md={4}>
                    <Field
                      id="gender"
                      type="radio"
                      value="male"
                      name="gender"
                      component={this.renderRadio}
                      label="Male"
                    />
                  </Col>

                  <Col md={4}>
                    <Field
                      id="gender"
                      type="radio"
                      value="female "
                      name="gender"
                      component={this.renderRadio}
                      label="Female"
                    />
                  </Col>
                </Row>
              </Col>

              <Col md={6}>
                <Row form>
                  <Col md={3}>
                    <Label style={{ marginTop: "10px" }}>Birthday: </Label>
                  </Col>
                  <Col md={9}>
                    <Field
                      id="birth_date"
                      type="date"
                      name="birth_date"
                      component={this.renderInput}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Field
                    id="country"
                    type="select"
                    name="country"
                    label="country"
                    component={this.renderCountryDropDown}
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Field
                    id="province"
                    type="select"
                    name="province"
                    component={this.renderProvinceDropDown}
                    label="province"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Field
                    id="Town / City"
                    type="text"
                    name="city"
                    component={this.renderInput}
                    label="Enter City / Town"
                  />
                </FormGroup>
              </Col>
            </Row>

            <MediaHeading
              heading={`WHICH FAT <i>bastard</i> WINE IS YOUR FAVOURITE?`}
            />

            <Row form className="d-flex justify-content-center">
              <Col md={5} xs={6}>
                <Field
                  id="chardonnay"
                  type="checkbox"
                  name="chardonnay"
                  component={this.renderCheckBox}
                  label="Chardonnay"
                />
              </Col>

              <Col md={5} xs={6}>
                <Field
                  id="sauvignon-blanc"
                  type="checkbox"
                  name="sauvignon_blanc"
                  component={this.renderCheckBox}
                  label="Sauvignon Blanc"
                />
              </Col>
            </Row>

            <Row form className="d-flex justify-content-center">
              <Col md={5} xs={6}>
                <Field
                  id="rose"
                  name="rose"
                  type="checkbox"
                  label="Ros&eacute;"
                  component={this.renderCheckBox}
                />
              </Col>

              <Col md={5} xs={6}>
                <Field
                  id="chenin-blanc"
                  name="chenin_blanc"
                  type="checkbox"
                  label="Chenin Blanc"
                  component={this.renderCheckBox}
                />
              </Col>
            </Row>

            <Row form className="d-flex justify-content-center">
              <Col md={5} xs={6}>
                <Field
                  id="cabernet-sauvignon"
                  name="cabernet_sauvignon"
                  type="checkbox"
                  label="Cabernet Sauvignon"
                  component={this.renderCheckBox}
                />
              </Col>

              <Col md={5} xs={6}>
                <Field
                  id="merlotc"
                  name="merlot"
                  type="checkbox"
                  label="Merlot"
                  component={this.renderCheckBox}
                />
              </Col>
            </Row>

            <Row form className="d-flex justify-content-center">
              <Col md={5} xs={6}>
                <Field
                  id="shiraz"
                  name="shiraz"
                  type="checkbox"
                  label="Shiraz"
                  component={this.renderCheckBox}
                />
              </Col>

              <Col md={5} xs={6}>
                <Field
                  id="the-golden-reserve"
                  name="the_golden_reserve"
                  type="checkbox"
                  label="The Golden Reserve"
                  component={this.renderCheckBox}
                />
              </Col>
            </Row>

            <Row form className="d-flex justify-content-center">
              <Col md={10} xs={12}>
                <Field
                  id="pinotage"
                  name="pinotage"
                  type="checkbox"
                  label="Pinotage"
                  component={this.renderCheckBox}
                />
              </Col>
            </Row>

            <MediaHeading
              heading={`WHICH SOCIAL MEDIA CHANNELS DO YOU USE ON A REGULAR BASIS?`}
            />

            <Row form className="d-flex justify-content-center">
              <Col md={5} xs={6}>
                <Field
                  id="facebook"
                  name="facebook"
                  label="Facebook"
                  type="checkbox"
                  component={this.renderCheckBox}
                />
              </Col>

              <Col md={5} xs={6}>
                <Field
                  id="twitter"
                  name="twitter"
                  label="Twitter"
                  type="checkbox"
                  component={this.renderCheckBox}
                />
              </Col>
            </Row>

            <Row form className="d-flex justify-content-center">
              <Col md={5} xs={6}>
                <Field
                  id="instagram"
                  name="instagram"
                  label="Instagram"
                  type="checkbox"
                  component={this.renderCheckBox}
                />
              </Col>

              <Col md={5} xs={6}>
                <Field
                  id="google_plus"
                  name="google_plus"
                  label="Google+"
                  type="checkbox"
                  component={this.renderCheckBox}
                />
              </Col>
            </Row>

            <Row form className="d-flex justify-content-center">
              <Col md={5} xs={6}>
                <Field
                  id="youtube"
                  name="youtube"
                  label="Youtube"
                  type="checkbox"
                  component={this.renderCheckBox}
                />
              </Col>

              <Col md={5} xs={6}>
                <Field
                  id="whatsapp"
                  name="whatsapp"
                  label="WhatsApp"
                  type="checkbox"
                  component={this.renderCheckBox}
                />
              </Col>
            </Row>

            <Row form className="d-flex justify-content-center">
              <Col md={5} xs={6}>
                <Field
                  id="pinterest"
                  name="pinterest"
                  label="Pinterest"
                  type="checkbox"
                  component={this.renderCheckBox}
                />
              </Col>

              <Col md={5} xs={6}>
                <Field
                  id="we_chat"
                  name="we_chat"
                  label="WeChat"
                  type="checkbox"
                  component={this.renderCheckBox}
                />
              </Col>
            </Row>

            <Row form className="d-flex justify-content-center">
              <Col md={10} xs={12}>
                <Field
                  id="snapchat"
                  name="snapchat"
                  label="Snapchat"
                  type="checkbox"
                  component={this.renderCheckBox}
                />
              </Col>

            </Row>

            <MediaHeading
              heading={`YES, I WOULD LIKE TO RECEIVE <br> FAT <i>bastard</i> UPDATES VIA:`}
            />

            <Row form className="d-flex justify-content-center">
              <Col md={5} xs={6}>
                <Field
                  id="subscribed"
                  name="subscribed"
                  label="Email"
                  type="checkbox"
                  component={this.renderCheckBox}
                />
              </Col>

              <Col md={5} xs={6}>
                <Field
                  id="subscribed_sms"
                  name="subscribed_sms"
                  label="SMS"
                  type="checkbox"
                  component={this.renderCheckBox}
                />
              </Col>
            </Row>

            <Row className="d-flex flex-row justify-content-center">
              <Col md={2} className="d-flex flex-row justify-content-center">
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    color: "#000",
                    backgroundColor: "#FFE11E",
                    border: "none",
                    marginTop: "20px",
                    padding: "12px 30px"
                  }}
                >
                  SUBMIT
                </Button>
              </Col>
            </Row>
          </form>
        </Container>
      </React.Fragment>
    );
  }
}

const validate = formValues => {
  const errors = {};

  if (!formValues.first_name) {
    // on run if the user did not enter a title
    errors.first_name = "You must enter a First Name";
  }

  if (!formValues.last_name) {
    // on run if the user did not enter a title
    errors.last_name = "You must enter a Last Name";
  }

  if (!formValues.email) {
    // on run if the user did not enter a title
    errors.email = "You must enter an email";
  }

  if (!formValues.accept_age) {
    // on run if the user did not enter a title
    errors.accept_age = "You must be older than 18 to continue.";
  }

  if (!formValues.accept_terms) {
    // on run if the user did not enter a title
    errors.accept_terms = "Agree to this.";
  }

  if (!formValues.birth_date) {
    // on run if the user did not enter a title
    errors.birth_date = "You must enter a Date of Birth";
  }

  return errors;
};

export default reduxForm({
  form: "entryForm",
  validate: validate
})(RegisterForm);
