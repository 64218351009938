import "./SocialCard.scss";
import React from "react";
import SocialCardContent from "./SocialCardContent";
import SocialCardFooter from "./SocialCardFooter";

export default function SocialCard(props) {
  const renderCard = () => {
    if (!props.article) {
      return <div>loading</div>;
    }

    let picture = "";
    let articleUrl = "";

    if (props.article.attachments.instagram_item) {
      picture = props.article.cover_url;
      articleUrl = props.article.attachments.instagram_item.link;
    }

    if (props.article.attachments.facebook_post) {
      picture = props.article.attachments.facebook_post.picture;
      articleUrl = props.article.attachments.facebook_post.link;
    }

    return (
      <div className="social-card">
        <a href={articleUrl} rel="noopener noreferrer" target="_blank">
          <div
            className="social-card-media"
            style={{
              background: `url(${picture})`,
              backgroundPosition: "right",
              backgroundSize: "cover",
              height: "280px"
            }}
          ></div>
        </a>

        <div className="social-card-content">
          <SocialCardContent article={props.article} />
          <SocialCardFooter article={props.article} />
        </div>
      </div>
    );
  };

  return renderCard();
}
