import "./RestaurantDetail.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';
import { fetchRestaurant } from "../../actions";
import ReactHtmlParser from "react-html-parser";
import Button from "../buttons/Button";
import Gallery from "../gallery/Gallery";
import Banner from "../slider/Banner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
import { FAT_BASTARD_API_IMAGE_PATH } from '../../utils/paths';

class WineDetail extends Component {
  componentDidMount() {
    this.props.fetchRestaurant(this.props.match.params.id);
  }

  renderTitle(title) {
    return title[0].map((item, index) => {
      return <p key={index}>{item}</p>;
    });
  }

  renderImageGallery() {
    const images = JSON.parse(this.props.restaurant.images);

    return images.map((img, index) => {
      return (
        <div>
          <img
          key={index}
          src={`${FAT_BASTARD_API_IMAGE_PATH}${img}`}
          alt=""
        />
        </div>
      );
    });
  }

  renderWebUrls() {
    const websites = this.props.restaurant.website_url.split(",");

    return websites.map((i, index) => {
      return (
        <div className="website_links" key={index}>
          <p>
            <a href={i} target="_blank"  rel="noopener noreferrer">
              {i}
            </a>
          </p>
        </div>
      );
    });
  }

  renderAddresses() {
    const addresses = this.props.restaurant.address.split(",");

    return addresses.map((i, index) => {
      return (
        <div key={index}>
          <p>{i}</p>
        </div>
      );
    });
  }

  renderphoneNumbers() {
    const numbers = this.props.restaurant.phone.split(",");

    return numbers.map((i, index) => {
      return (
        <div key={index}>
          <p>{i}</p>
        </div>
      );
    });
  }

  renderDekstopImage = (imagePAth, alt) => {
    return window.innerWidth > 800 && <img src={imagePAth} alt={alt} />;
  };

  renderMobiImage = (imagePAth, alt) => {
    return window.innerWidth < 800 && <img src={imagePAth} alt={alt} />;
  };

  renderRestaurant = restaurant => {
    if (!this.props.restaurant) {
      return <div>Loading...</div>;
    }

    return (
      <React.Fragment>
         <Helmet>
          <meta charSet="utf-8" />
          <title>{`FAT bastard wines | VIB | Restaurants | ${restaurant.title}`}</title>
          <meta name="description" content={restaurant.meta_description} />
          <link rel="canonical" href={window.location} />
        </Helmet>

        <div className="ui container">
          <div className="restaurant-container">
            <div className="restarurant-box">
              <h2 className="title">{restaurant.title}</h2>
              {/* <h3 className="gold">Website</h3>
              {this.renderWebUrls()} */}

              {/* <h3 className="gold">Address</h3> */}
              {ReactHtmlParser(this.props.restaurant.address)}

              {/* <h3 className="gold">Phone</h3>
              {this.renderphoneNumbers()} */}

              <div className="social-icons">
                <ul className="social-icon-list">
                  {restaurant.facebook_url && (
                    <li>
                      <a
                        href={restaurant.facebook_url}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faFacebookF} />
                      </a>
                    </li>
                  )}

                  {restaurant.twitter_url && (
                    <li>
                      <a
                        href={restaurant.twitter_url}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                    </li>
                  )}

                  {restaurant.instagram_url && (
                    <li>
                      <a
                        href={restaurant.instagram_url}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              <br />
            </div>
                    
            <div className="restarurant-box">
              <img
                src={`${FAT_BASTARD_API_IMAGE_PATH}${restaurant.image}`}
                alt=""
              />
            </div>
          </div>

          <div className="content">{ReactHtmlParser(restaurant.content)}</div>
          <div className="restaurant-gallery">{this.renderImageGallery()}</div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    if (!this.props.isVerified) {
      return <div>loading</div>;
    }

    return (
      <div className="restaurant-detail">
        {/* <div style={{ height: "320px" }}> */}
        <Banner
          title="VIB RESTAURANTS"
          breadcrumbs="HOME / VIB RESTAURANTS"
          subtitle={`
            <p>To <strong style="color: rgb(214, 176, 82)">LIVE LARGE</strong>, you need to gallop to your local watering hole and take a chunk-size bite out of life. We unreservedly encourage you to try one of our VIB restaurants. These restaurants have been handpicked for their incomparable service, delectable food, and for being the perfect locale to enjoy a bottle or 2 of <strong style="color: rgb(214, 176, 82)">FAT <em>bastard</em></strong> by the hearty mouthful.</p>
          `}
        />
        {/* </div> */}
        {this.renderRestaurant(this.props.restaurant)}
        <div className="container" style={{ textAlign: 'center', paddingBottom: '70px'}}>
          <Button  color="purple" toUrl="/restaurants" title="RETURN" />
        </div>
        <Gallery pageId={7} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    restaurant: state.restaurants[ownProps.match.params.id],
    isVerified: state.isVerified
  };
};

export default connect(
  mapStateToProps,
  {
    fetchRestaurant
  }
)(WineDetail);
