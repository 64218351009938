
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Col, Row, Media } from "reactstrap";

export default function MediaHeading(props) {
  return (
    <Row form>
      <Col md={12}>
        <Media className="pt-5 pb-5">
          <Media body>
            <Media heading className="text-center form-header" >
              {ReactHtmlParser(props.heading)}
            </Media>
          </Media>
        </Media>
      </Col>
    </Row>
  );
}
